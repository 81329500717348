import {
  Controller
} from "stimulus"
import {
  DirectUpload
} from "@rails/activestorage"
import Dropzone from "dropzone"
import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter
} from "helpers"

// Dropzone.autoDiscover = false
// Dropzone.prototype.defaultOptions.dictDefaultMessage = "Arrastra los archivos aquí para subirlos"
// Dropzone.prototype.defaultOptions.dictFallbackMessage = "Su navegador no admite la carga de archivos de arrastrar y soltar"
// Dropzone.prototype.defaultOptions.dictFallbackText = "Utilice el siguiente formulario de respaldo para cargar sus archivos como en los viejos tiempos"
// Dropzone.prototype.defaultOptions.dictFileTooBig = "El archivo es demasiado grande ({{filesize}}MiB). Tamaño máximo de archivo: {{maxFilesize}}MiB."
// Dropzone.prototype.defaultOptions.dictInvalidFileType = "No puedes subir archivos de este tipo"
// Dropzone.prototype.defaultOptions.dictResponseError = "El servidor respondió con el código {{statusCode}}"
// Dropzone.prototype.defaultOptions.dictCancelUpload = "Cancelar carga"
// Dropzone.prototype.defaultOptions.dictCancelUploadConfirmation = "¿Estás seguro de que deseas cancelar esta carga?"
// Dropzone.prototype.defaultOptions.dictRemoveFile = "Eliminar archivo"
// Dropzone.prototype.defaultOptions.dictMaxFilesExceeded = "No puedes subir más archivos"

export default class extends Controller {

  static targets = ["input"]

  connect() {
    this.dropZone = createDropZone(this)
    if (this.select) {
      this.contentType = this.select.options[this.select.selectedIndex].value
      this.assignAcceptedFiles(this.contentType)
    }
    this.hideFileInput()
    this.hideDropzone()
    this.bindEvents()
  }

  // Private
  hideDropzone() {
    if (this.contentType == "") {
      this.dropzoneGroup.style.display = "none"
    }
  }

  hideFileInput() {
    this.inputTarget.disabled = true
    this.inputTarget.style.display = "none"
    this.inputTarget.classList.add("form-control")
  }

  bindEvents() {
    this.dropZone.on("addedfile", (file) => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start()
      }, 500)
    })

    this.dropZone.on("complete", (file) => {
      this.assignAcceptedFiles(this.contentType)
    });

    this.dropZone.on("error", (file) => {
      this.assignAcceptedFiles(this.contentType)
    });

    this.dropZone.on("maxfilesexceeded", (file) => {
      this.dropZone.removeAllFiles(true);
      this.dropZone.addFile(file);
    });

    this.dropZone.on("removedfile", (file) => {
      file.controller && removeElement(file.controller.hiddenInput)
    })

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort()
    })

    this.dropZone.on("processing", (file) => {
      this.submitButton.disabled = true
    })

    this.dropZone.on("queuecomplete", (file) => {
      this.submitButton.disabled = false
      this.assignAcceptedFiles(this.contentType)
    })

    $(this.select).on("change", (e) => {
      this.contentType = e.target.value
      this.assignAcceptedFiles(e.target.value)
    })
  }

  assignAcceptedFiles(fileType) {
    if (fileType != "" && fileType != "card_game") {
      if (this.dropzoneGroup) {
        this.dropzoneGroup.style.display = ""
        console.log(fileType);
        if (fileType == 'pdf') {
          $(this.dropZone.hiddenFileInput).attr('accept', '.pdf');
        } else if (fileType == 'audio') {
          $(this.dropZone.hiddenFileInput).attr('accept', '.mp3');
        } else if (fileType == 'video') {
          $(this.dropZone.hiddenFileInput).attr('accept', '.mp4');
        } else if (fileType == 'file') {
          $(this.dropZone.hiddenFileInput).attr('accept', '.zip');
        } else if (fileType == 'docx') {
          $(this.dropZone.hiddenFileInput).attr('accept', '.docx');
          // $(this.dropZone.hiddenFileInput).attr('accept', '.msword');
        } else if (fileType == 'xlsx') {
          $(this.dropZone.hiddenFileInput).attr('accept', '.xlsx');
        } else if (fileType == 'pptx') {
          $(this.dropZone.hiddenFileInput).attr('accept', '.pptx');
        }
      }
    } else {
      this.dropzoneGroup.style.display = "none"
    }
  }

  get headers() {
    return {
      "X-CSRF-Token": getMetaValue("csrf-token")
    }
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url")
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles")
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true
  }

  get selectReference() {
    return this.data.get("selectReference")
  }

  get groupReference() {
    return this.data.get("groupReference")
  }

  get form() {
    return this.element.closest("form")
  }

  // element to select content type authorized
  get select() {
    return findElement(this.form, this.selectReference)
  }

  // class group dropzone, label and preview
  get dropzoneGroup() {
    return findElement(this.form, this.groupReference)
  }

  get submitButton() {
    return findElement(this.form, "input[type=submit], button[type=submit]")
  }

}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this)
    this.source = source
    this.file = file
  }

  start() {
    this.file.controller = this
    this.hiddenInput = this.createHiddenInput()
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput)
        this.emitDropzoneError(error)
      } else {
        this.hiddenInput.value = attributes.signed_id
        this.emitDropzoneSuccess()
      }
    })
  }

  // Private
  createHiddenInput() {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = this.source.inputTarget.name
    insertAfter(input, this.source.inputTarget)
    return input
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener("progress", event => this.uploadRequestDidProgress(event))
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element
    const progress = event.loaded / event.total * 100
    findElement(this.file.previewTemplate, ".dz-upload").style.width = `${progress}%`
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit("processing", this.file)
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit("error", this.file, error)
    this.source.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit(" ", this.file)
    this.source.dropZone.emit("complete", this.file)
  }
}

// Top level...
function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file)
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller)
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false
  })
}
