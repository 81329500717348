import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "card", "next", "rotate", "selector", "featured", "option1", "option2", "option3" ]

  initialize() {
    this.currentCard = null
    this.currentIndexCard = null
    this.correctOptionPosition = null
    this.positions = [1, 2, 3]
  }

  connect() {
    if (this.cards.length > 0) {
      this.currentIndexCard = 0
      this.currentCard = this.cards[this.currentIndexCard]
    }
    this.hideElements()
  }

  hideElements() {
    this.cards.forEach((el, i) => {
      el.id = "flipcard-"+i;
      el.classList.add("flipcard")
      el.classList.add("d-none")
    })
    this.currentCard.classList.remove("d-none")
    this.selector.classList.add("d-none")
    this.next.classList.add("invisible")
  }

  clickRotate(e) {
    this.currentCard.classList.toggle("flipcard-rotate")
    this.next.classList.remove("invisible")
  }

  clickNext(e) {
    this.currentCard.classList.add("d-none")
    this.currentCard.classList.remove("flipcard-rotate")
    if (!this.selector.classList.contains("d-none")) {
      this.moveNextCard()
      this.selector.classList.add("d-none")
      this.rotate.classList.remove("d-none")
    } else {
      if ((this.currentIndexCard + 1) % 3 == 0) {
        this.setupSelector()
        this.displaySelector()
      } else {
        this.moveNextCard()
      }
    }
    this.next.classList.toggle("invisible")
  }

  moveNextCard() {
    this.nextIndex()
    this.currentCard = this.cards[this.currentIndexCard]
    this.currentCard.classList.toggle("d-none")
  }

  clickOption(e) {
    if (e.target.closest(".option").position == this.correctOptionPosition) {
      this.next.classList.remove("invisible")
      e.target.closest(".option").parentElement.classList.add("option-correct")
      e.target.closest(".option").parentElement.classList.remove("card-item")
      this.featured.previousElementSibling.classList.remove("d-none");
      this.featured.previousElementSibling.classList.add("d-flex");
    } else {
      e.target.closest(".option").parentElement.classList.add("option-fail")
      e.target.closest(".option").parentElement.classList.remove("card-item")
    }
  }

  setupSelector() {
    this.option1.parentElement.classList.remove("option-fail")
    this.option1.parentElement.classList.remove("option-correct")
    this.option1.parentElement.classList.add("card-item")
    this.option2.parentElement.classList.remove("option-fail")
    this.option2.parentElement.classList.remove("option-correct")
    this.option2.parentElement.classList.add("card-item")
    this.option3.parentElement.classList.remove("option-fail")
    this.option3.parentElement.classList.remove("option-correct")
    this.option3.parentElement.classList.add("card-item")
    this.featured.previousElementSibling.classList.add("d-none");
    this.featured.previousElementSibling.classList.remove("d-flex");
    this.positions = this.shuffle(this.positions)
    this.correctOptionPosition = Math.floor(Math.random() * 3)
    this.featured.innerHTML = this.cardContent(this.positions[this.correctOptionPosition], "front")
    this.option1.position = 0
    this.option2.position = 1
    this.option3.position = 2
    this.option1.innerHTML = this.cardContent(this.positions[0], "back")
    this.option2.innerHTML = this.cardContent(this.positions[1], "back")
    this.option3.innerHTML = this.cardContent(this.positions[2], "back")
  }

  cardContent(index, side) {
    let position = this.currentIndexCard+1-index;
    return this.cards[position].getElementsByClassName("flipcard-"+side)[0].innerHTML
  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  displaySelector() {
    this.rotate.classList.add("d-none")
    this.currentCard.classList.add("d-none")
    this.selector.classList.toggle("d-none")
  }

  nextIndex() {
    this.currentIndexCard += 1
    if (this.currentIndexCard >= this.cards.length) {
      this.currentIndexCard =  0
    }
  }

  get next() { return this.nextTarget }

  get rotate() { return this.rotateTarget }

  get selector() { return this.selectorTarget }

  get featured() { return this.featuredTarget }

  get option1() { return this.option1Target }

  get option2() { return this.option2Target }

  get option3() { return this.option3Target }

  get cards() { return this.cardTargets }
}
