import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "label", "image" ]

  connect() {
    this.toggleSelector(this.selected)
  }

  onChange(e) {
    this.toggleSelector(e.target.value.toLowerCase())
  }

  toggleSelector(selected) {
    if (selected=="label") {
      this.label.style.display = "block";
      this.image.style.display = "none";
    } else {
      this.label.style.display = "none";
      this.image.style.display = "block";
    }
  }

  get label() { return this.labelTarget }

  get image() { return this.imageTarget }

  get selected() { return this.data.get("selected") || 'label' }
}
