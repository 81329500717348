import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["optionContent", "selectContent", "dropzoneGroup"]

  initialize() {
    this.selectOptionActivity = null
  }

  connect() {
  }

  onChangeActivity(e) {
    this.selectOptionActivity = e.target.selectedOptions[0].dataset.contents
    this.selectContent.selectedIndex = null
    this.dropzoneGroup.style.display = "none"
    this.refreshContent()
  }

  refreshContent() {
    let allowContents = this.selectOptionActivity .split(',')
    this.optionContents.forEach((el, i) => {
      el.classList.add('d-none')
    })
    this.optionContents.forEach((el, i) => {
      let str = el.value
      if (allowContents.some(v => str.includes(v))) {
        el.classList.remove('d-none')
      }
    })
  }

  get dropzoneGroup() { return this.dropzoneGroupTarget }

  get selectContent() { return this.selectContentTarget }

  get optionContents() { return this.optionContentTargets }
}
