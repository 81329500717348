import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "preview", "label", "uploader" ]

  connect() {
    console.log("Upload Preview")
  }

  onChange(e) {
    if (this.hasPreviewTarget) {
      var reader = new FileReader();
      reader.here = this
      reader.onload = function (f) {
        // get loaded data and render thumbnail.
        this.here.preview.src = f.target.result;
      };
      // read the image file as a data URL.
      reader.readAsDataURL(e.target.files[0]);
    }
    if (this.hasLabelTarget) {
      this.label.innerHTML = "Foto Seleccionada"
    }
    if (this.hasUploaderTarget) {
      this.uploader.classList.add('has-file')
    }
  }

  onRemove(e) {
    if (this.hasPreviewTarget) {
      this.preview.src = "";
    }
    if (this.hasLabelTarget) {
      this.label.innerHTML = "Seleccionar Foto"
    }
    if (this.hasUploaderTarget) {
      this.uploader.classList.remove('has-file')
    }
  }

  get preview() {
    return this.previewTarget
  }

  get label() {
    return this.labelTarget
  }

  get uploader() {
    return this.uploaderTarget
  }
}
