import { Controller } from "stimulus";

import $ from 'jquery';
import Select2 from "select2"

export default class extends Controller {
    connect() {
        $('.school_subject_ids').select2({
            placeholder: 'Selecciona una o varias materias',
            allowClear: true,
            allowHtml: true,
            tags: true,
            closeOnSelect : false,
            language: {
              noResults: () => "No hay resultado"
            }
        });
    }
}
