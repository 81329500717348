import { Controller } from "stimulus";

import $ from 'jquery';

export default class extends Controller {
  grades = { 1: "Primero", 2: "Segundo", 3: "Tercero", 4: "Cuarto", 5: "Quinto", 6: "Sexto" }

  connect() {
    const urlParams = window.location.pathname.split('/');
    let method = [...urlParams].pop();

    let editForm = $('.edit_order');

    $('#scholar-levels-filter').select2({
      allowClear: false,
      allowHtml: true,
      tags: false,
      closeOnSelect: true
    });

    $('#grades-filter').select2({
      allowClear: false,
      allowHtml: true,
      tags: false,
      closeOnSelect: true
    });

    if (!editForm.length) {
      this.disableFilters();
      this.resetFilters();
    } else {
      this.fetchStudents();
    }

    $("#scholar-levels-filter").on('select2:select', (e) => {
      let url = $("#scholar-levels-filter").select2().attr('data-url');
      let scholarLevelId = $("#scholar-levels-filter").val()

      if (scholarLevelId != 0) {
        $('#grades-filter').html('');
        $('#students-filter').html('');
        this.fetchGrades(url, scholarLevelId);
        $('#grades-filter').prop("disabled", false);
      } else {
        $('#grades-filter').val($('#grades-filter option:eq(0)').val()).trigger('change');
        $('#students-filter').val($('#students-filter option:eq(0)').val()).trigger('change');
        this.disableFilters();
      }
    });

    $("#grades-filter").on('select2:select', (e) => {
      let gradeId = $("#grades-filter").val()

      if (gradeId != 0) {
        $('#students-filter').html('');
        this.fetchStudents();
        $('#students-filter').prop("disabled", false);
      } else {
        $('#students-filter').val($('#students-filter option:eq(0)').val()).trigger('change');
        $('#students-filter').prop("disabled", true);
      }
    });
  }

  fetchGrades(url, scholarLevelId) {
    const urlParams = window.location.pathname.split('/');
    let schoolID = urlParams[2];

    $.ajax({
      type: "get",
      url: `${url}/${scholarLevelId}/grades?school_id=${schoolID}`,
      success: (data) => {
        $('#grades-filter').prop("disabled", false);

        $('#grades-filter').prepend($('<option>', {
          value: 0,
          text: "Selecciona un Grado",
          selected: 'selected'
        })).select2();
        data.map(grade => {
          $('#grades-filter').append($('<option>', {
            value: grade.id,
            text: this.grades[grade.name]
          })).select2();
        });
      },
    });
  }

  fetchStudents() {
    const urlParams = window.location.pathname.split('/');
    let schoolID = urlParams[2];
    let url = $("#grades-filter").select2().attr('data-url');
    let scholarLevelId = $("#scholar-levels-filter").val()
    let gradeId = $("#grades-filter").val()

    $('#students-filter').select2({
      language: {
        noResults: () => "No hay resultado",
        searching: () => "Buscando...",
        inputTooShort: function (args) {
          let remainingChars = args.minimum - args.input.length;
          let message = 'Por favor, introduzca ' + remainingChars + ' car';
          message += remainingChars == 1 ? 'ácter' : 'acteres';
          return message;
        }
      },
      ajax: {
        url: `${url}/${scholarLevelId}/grades/${gradeId}/filter_students?school_id=${schoolID}`,
        dataType: 'json',
        delay: 250,
        cache: true,
        processResults: (data) => { return { results: data } }
      },
      minimumInputLength: 3,
      placeholder: 'Buscar estudiante',
      multiple: false,
    });
  }

  resetFilters() {
    $('#scholar-levels-filter').prepend($('<option>', {
      value: 0,
      text: "Selecciona un Nivel Escolar",
      selected: "selected"
    })).select2();

    $('#grades-filter').prepend($('<option>', {
      value: 0,
      text: "Selecciona un Grado",
      selected: "selected"
    })).select2();

    $('#students-filter').prepend($('<option>', {
      value: 0,
      text: "Selecciona un Alumno",
      selected: "selected"
    })).select2();
  }

  disableFilters() {
    $('#grades-filter').prop("disabled", true);
    $('#students-filter').prop("disabled", true);
  }
}