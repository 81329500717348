import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "front", "back", "card" ]

  connect() {
    this.hideAllLessFirst()
  }

  hideAllLessFirst() {
    this.cards.forEach((el, i) => {
      el.id = "card-"+i;
      el.classList.add("flipcard")
      el.classList.add("d-none")
      el.onclick = this.rotate
    })
    this.cards[0].classList.remove("d-none")
  }

  rotate(e) {
    var card = e.target.closest(".flipcard")
    card.classList.toggle("rotate")
  }

  get front() { return this.frontTarget }

  get back() { return this.backTarget }

  get cards() { return this.cardTargets }

}
